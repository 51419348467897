import { type ChainflipChain } from '@chainflip/utils/chainflip';
import { Tooltip, CopyButton } from '@/shared/components';
import { SendIcon, ShieldTickIcon } from '@/shared/icons/small';
import HighlightableAddress from './HighlightableAddress';

type AddressesProps = {
  destinationAddress: string;
  destinationChain: ChainflipChain;
  refundAddress: string | null | undefined;
  sourceChain: ChainflipChain;
};

export const Addresses = ({
  destinationAddress,
  destinationChain,
  refundAddress,
  sourceChain,
}: AddressesProps) => (
  <div className="flex flex-col gap-y-1 whitespace-nowrap font-aeonikMono">
    <div className="flex flex-row items-center space-x-1">
      <div title="Destination address">
        <SendIcon />
      </div>
      <Tooltip content={destinationAddress}>
        <HighlightableAddress chain={destinationChain} address={destinationAddress} />
      </Tooltip>
      <CopyButton textToCopy={destinationAddress} />
    </div>
    {refundAddress && (
      <div className="flex flex-row items-center space-x-1">
        <div title="Refund address">
          <ShieldTickIcon />
        </div>
        <Tooltip content={refundAddress}>
          <HighlightableAddress chain={sourceChain} address={refundAddress} />
        </Tooltip>
        <CopyButton textToCopy={refundAddress} />
      </div>
    )}
  </div>
);
