import { type ChainflipChain } from '@chainflip/utils/chainflip';
import { abbreviate } from '@chainflip/utils/string';
import classNames from 'classnames';
import { Link } from './atoms/Link';
import useLocalStorage from '../hooks/useLocalStorage';
import { buildExplorerLink } from '../utils/chainflip';

export default function HighlightableAddress({
  chain,
  address,
  className,
  disableHighlight = false,
}: {
  chain: ChainflipChain;
  address: string;
  className?: string;
  disableHighlight?: boolean;
}) {
  const [highlightAddress, setHighlightAddress] = useLocalStorage<string | undefined>(
    'highlightAddress',
    // don't use the value if it's in storage
    () => undefined,
  );

  return (
    <Link
      noPropagate
      href={buildExplorerLink(chain, 'address', address)}
      underline
      onMouseOver={() => setHighlightAddress?.(address)}
      onMouseOut={() => setHighlightAddress?.('')}
      className={classNames(
        !disableHighlight && highlightAddress === address
          ? 'border-cf-green-1 bg-cf-green-4'
          : 'border-transparent',
        'rounded-md border border-dashed p-1',
        className,
      )}
    >
      {abbreviate(address)}
    </Link>
  );
}
